<template>
  <BaseLayout :locale="locale">
    <PageHeader
      slot="header"
      logo-image="holding"
      :country="country"
      :countries="countries"
      :locale="locale"
      :locales="locales"
      :navigation-actions="resolvedNavigationActions"
      :navigation-items="resolvedMainNavigationItems"
    />
    <slot />
    <PageFooter
      slot="footer"
      logo-image="holding"
      :items="resolvedFooterNavigationItems"
      :copyright="copyright"
    >
      <template v-if="locale.columns.country === 'ch'">
        <template v-if="locale.columns.language === 'de'">
          Beisheim&nbsp;Holding<br><br>
          Neuhofstrasse&nbsp;4<br>
          CH-6340&nbsp;Baar<br><br>
          Telefon: <a href="tel:+41417687660">+41&nbsp;41&nbsp;768&nbsp;76&nbsp;60</a><br>
          E-Mail: <a href="mailto:info@beisheim.com">info@beisheim.com</a>
        </template>
        <template v-else>
          Beisheim&nbsp;Holding<br><br>
          Neuhofstrasse&nbsp;4<br>
          CH-6340&nbsp;Baar<br><br>
          Phone: <a href="tel:+41417687660">+41&nbsp;41&nbsp;768&nbsp;76&nbsp;60</a><br>
          Email: <a href="mailto:info@beisheim.com">info@beisheim.com</a>
        </template>
      </template>
      <template v-else>
        <template v-if="locale.columns.language === 'de'">
          Beisheim&nbsp;Capital GmbH<br><br>
          Breite&nbsp;Straße&nbsp;22<br>
          40213&nbsp;Düsseldorf<br>
          Deutschland<br><br>
          Telefon: <a href="tel:+492116877560">+49&nbsp;211&nbsp;687&nbsp;75&nbsp;60</a><br>
          E-Mail: <a href="mailto:kontakt@beisheim.com">kontakt@beisheim.com</a>
        </template>
        <template v-else>
          Beisheim&nbsp;Capital GmbH<br><br>
          Breite&nbsp;Str.&nbsp;22<br>
          40213&nbsp;Düsseldorf<br>
          Germany<br><br>
          Phone: <a href="tel:+492116877560">+49&nbsp;211&nbsp;687&nbsp;75&nbsp;60</a><br>
          Email: <a href="mailto:kontakt@beisheim.com">kontakt@beisheim.com</a>
        </template>
      </template>
    </PageFooter>
    <OffscreenNavigation
      slot="afterFooter"
      logo-image="holding"
      :country="country"
      :countries="countries"
      :locale="locale"
      :locales="locales"
      :items="resolvedMainNavigationItems"
      :actions="resolvedNavigationActions"
    />
  </BaseLayout>
</template>

<script>
import { sortBy } from 'lodash'
import { Navigation, Page } from 'beisheim/models'
import BaseLayout from 'beisheim/components/layouts/base'
import PageHeader from 'beisheim/components/elements/page-header'
import PageFooter from 'beisheim/components/elements/page-footer'
import OffscreenNavigation from 'beisheim/components/elements/offscreen-navigation'
import SearchIcon from 'beisheim/assets/images/icons/beisheim-stiftung-search.svg'

export default {
  components: {
    OffscreenNavigation,
    PageFooter,
    PageHeader,
    BaseLayout
  },
  props: {
    country: {
      type: Object,
      default: () => ({})
    },
    countries: {
      type: Array,
      default: () => []
    },
    locale: {
      type: Object,
      default: () => ({})
    },
    locales: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    copyright () {
      if (this.locale.columns.country === 'ch') {
        return `Beisheim Holding © ${new Date().getFullYear()}`
      }

      return `Beisheim Capital © ${new Date().getFullYear()}`
    },
    resolvedMainNavigationItems () {
      const resource = Navigation.query()
        .where('name', 'mainNavigation')
        .where(r => r.country === this.locale.columns.country && r.language === this.locale.columns.language)
        .first()
      if (!resource) { return [] }
      return sortBy(resource.children, 'sort_key')
    },
    resolvedFooterNavigationItems () {
      const resource = Navigation.query()
        .where('name', 'footerNavigation')
        .where(r => r.country === this.locale.columns.country && r.language === this.locale.columns.language)
        .first()
      if (!resource) { return [] }
      return sortBy(resource.children, 'sort_key')
    },
    resolvedNavigationActions () {
      const resource = Navigation.query()
        .where('name', 'actions')
        .where(r => r.country === this.locale.columns.country && r.language === this.locale.columns.language)
        .first()
      if (!resource) { return [] }
      return sortBy(resource.children, 'sort_key').map((resource) => {
        const icons = {
          'search': SearchIcon
        }
        return {
          resource,
          icon: icons[resource.name]
        }
      })
    }
  },
  methods: {
    resolvePages (locale, pages) {
      const language = locale.columns.language
      const country = locale.columns.country
      const resolvedPages = Page.where((p) => {
        return p.language === language && p.country === country && pages.includes(p.name)
      }).get()
      return resolvedPages
    }
  },
  head () {
    return {
      titleTemplate: this.$country === 'ch' ? '%s | Beisheim Holding' : '%s | Beisheim Capital',
      title: 'Document',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Beisheim Capital'
        },
        {
          name: 'google-site-verification',
          content: 'E7wvHP2JEoJ3l26fXNrfyDgZD2gNwu9SO90V5LfBkRM'
        }
      ],
      link: [
        { rel: 'icon', type: 'image/x-icon', href: `/favicons/beisheim_holding_favicon_32x32.ico` },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: `/favicons/beisheim_holding_favicon_32x32.png`
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '57x57',
          href: `/favicons/beisheim_holding_favicon_57x57.png`
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '72x72',
          href: `/favicons/beisheim_holding_favicon_72x72.png`
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '128x128',
          href: `/favicons/beisheim_holding_favicon_128x128.png`
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '144x144',
          href: `/favicons/beisheim_holding_favicon_144x144.png`
        }
      ]
    }
  }
}
</script>
